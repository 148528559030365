import React from 'react';

function App() {
    return (
        <marquee>
            &lt;marquee&gt; should never have gone out of style.
        </marquee>
    );
}

export default App;
